import React from 'react'
import './newsTable.css'
import styles from './NewsTable.module.css'
import { ReactComponent as NotificationActive } from '../../assets/images/notifications-active.svg'

// Example of a data array that
// you might receive from an API
const data = [
  {
    impact: 'High',
    date: 'Feb 27, 16:50',
    timeLeft: { date: '19h 43min', flag: 'flag', nationName: 'JPY' },
    newsType: 'Retail Sales MoM (Jan)',
    previouse: { firstValue: '-1%', secondValue: '0.2%' },
  },
  {
    impact: 'Medium',
    date: 'Feb 27, 16:50',
    timeLeft: { date: '19h 43min', flag: 'flag', nationName: 'JPY' },
    newsType: 'Retail Sales MoM (Jan)',
    previouse: { firstValue: '-1%', secondValue: '0.2%' },
  },
  {
    impact: 'Medium',
    date: 'Feb 27, 16:50',
    timeLeft: { date: '19h 43min', flag: 'flag', nationName: 'JPY' },
    newsType: 'Retail Sales MoM (Jan)',
    previouse: { firstValue: '-1%', secondValue: '0.2%' },
  },
  {
    impact: 'Low',
    date: 'Feb 27, 16:50',
    timeLeft: { date: '19h 43min', flag: 'flag', nationName: 'JPY' },
    newsType: 'Retail Sales MoM (Jan)',
    previouse: { firstValue: '-1%', secondValue: '0.2%' },
  },
  {
    impact: 'High',
    date: 'Feb 27, 16:50',
    timeLeft: { date: '19h 43min', flag: 'flag', nationName: 'JPY' },
    newsType: 'Retail Sales MoM (Jan)',
    previouse: { firstValue: '-1%', secondValue: '0.2%' },
  },
]

function handleBackground(elem) {
  switch (elem) {
    case 'High':
      return 'high'
    case 'Medium':
      return 'medium'
    case 'Low':
      return 'low'
    default:
      return ''
  }
}
function handleImpact(elem) {
  switch (elem) {
    case 'High':
      return 'red'
    case 'Medium':
      return 'yellow'
    case 'Low':
      return 'green'
    default:
      return ''
  }
}

function NewsTable() {
  return (
    <div className={styles.newsTable}>
      <table>
        <tr className={styles.newsTableHeader}>
          <th>Impact</th>
          <th>Date</th>
          <th colSpan={3}>Time left</th>
          <th>News type</th>
          <th colSpan={2}>Previouse</th>
          <th className={styles.background} colSpan={3}>
            Vote
          </th>
        </tr>
        {data.map((val, key) => {
          return (
            <tr className={handleBackground(val.impact)} key={key}>
              <td className={styles.padding}>
                <span className={handleImpact(val.impact)}>{val.impact}</span>
              </td>
              <td>{val.impact}</td>
              <td className={styles.newsTableItem}>{val.timeLeft.date}</td>
              <td>{val.timeLeft.flag}</td>
              <td> {val.timeLeft.nationName}</td>
              <td>{val.newsType}</td>
              <td>{val.previouse.firstValue}</td>
              <td>{val.previouse.secondValue}</td>

              <td className={styles.thWidth}>
                <button className={styles.buyBtn}>Buy</button>
              </td>
              <td className={styles.thWidth}>
                <button className={styles.sellBtn}>Sell</button>
              </td>
              <td className={styles.thWidth}>{<NotificationActive />}</td>
            </tr>
          )
        })}
      </table>
    </div>
  )
}

export default NewsTable
