import React from 'react';
import Main from '../../components/main/Main';
import styles from './Help.module.css'

const Help = () => {
    return (
        <Main>
            Help
        </Main>
    );
}

export default Help;
