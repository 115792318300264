import React from 'react';
import Main from '../../components/main/Main';
import styles from './Settings.module.css'

const Settings = () => {
    return (
        <Main>
            Settings
        </Main>
    );
}

export default Settings;
