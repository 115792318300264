import React from "react";
import styles from "./Analysis.module.css";

const Analysis = () => {
  return (
    <div className={styles.mainPart}>
      <p className={styles.title}>Quick analysis</p>
      <main className={styles.main}>vdfvfdv</main>;
    </div>
  );
};

export default Analysis;
