import React from 'react';
import Main from '../../components/main/Main';
import styles from './Markets.module.css'

const Markets = () => {
    return (
        <Main>
            Markets
        </Main>
    );
}

export default Markets;
