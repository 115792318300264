import React from 'react';
import Main from '../../components/main/Main';
import styles from './Charts.module.css'

const Charts = () => {
    return (
        <Main>
            Charts
        </Main>
    );
}

export default Charts;
