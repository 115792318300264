import React from 'react'
import Main from '../../components/main/Main'
import styles from './News.module.css'
import MyTabs from '../../components/tabs/tabs'
import Profiletable from '../../components/profileTable/ProfileTable'
import Myorders from '../../components/myOrders/MyOrders'
import Balancedetails from '../../components/balanceDetails/BalanceDetails'
import Mostpopular from '../../components/mostPopular/MostPopular'
import MostpopularNews from '../../components/MostPopularNews/MostPopularNews'
import NewsTable from './NewsTable'

const News = () => {
  const handleNews = () => {
    const tabsItems1 = [
      'All',
      'Most popular',
      'Latest',
      'Market',
      'Latest',
      'Market',
      'Latest',
      'Traders',
      'Stocs',
      'Subscriptions',
      'Stocs',
      'Subscribe',
      'Subscribe',
      'Stocs',
      'Subscribe',
    ]
    const tabsData = {
      All: 'All',
      'Most popular': <MostpopularNews />,
      Top: 'Latest',
      Traders: 'Traders',
      Subscriptions: 'Subscriptions',
      Stocs: 'Stocs',
      Subscribe: 'Subscribe',
    }
    return (
      <div className={styles.mainPart}>
        <div className={styles.traders}>
          <MyTabs WIDTHSIZE={1400} tabsData={tabsData} arrs={tabsItems1} />
        </div>
      </div>
    )
  }

  const tabsItems = [
    'All',
    'Indices Futures',
    'Stocs',
    'Traders',
    'Subscriptions',
    'Stocings',
    'Subscribe',
  ]
  const tabsData = {
    All: <Profiletable />,
  }
  return (
    <Main>
      <div className={styles.newsBlock}>
        {handleNews()}
        <main className={styles.topMain}>
          <p className={styles.economicNews}>Economic News</p>
          <div>
            {/* <MyTabs WIDTHSIZE={'100%'} tabsData={tabsData} arrs={tabsItems} /> */}
            {<NewsTable />}
          </div>
        </main>
      </div>
    </Main>
  )
}

export default News
