import React from 'react';
import Main from '../../components/main/Main';
import styles from './Discover.module.css'

const Discover = () => {
    return (
        <Main>
            Discover
        </Main>
    );
}

export default Discover;
