import React from 'react';
import Main from '../../components/main/Main';
import styles from './Trades.module.css'

const Trades = () => {
    return (
        <Main>
            Trades
        </Main>
    );
}

export default Trades;
