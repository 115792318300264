import React from "react";
import Logregheader from "../../components/logRegHeader/LogRegHeader";
import Logregmain from "../../components/logRegMain/LogRegMain";

const Openpage = () => {
  return (
    <>
      <Logregheader />
      <Logregmain />
    </>
  );
};

export default Openpage;
